import httpClient from './http-client';

const getBalance = () => {
    return httpClient.get('/api/post-payments/balance');
};

const initiatePayment = ({ amount, email }) => {
    return httpClient.post('/api/post-payments/initiate-payment', { amount, email });
};

const getPaymentDetails = (paymentId) => {
    return httpClient.get(`/api/post-payments/${paymentId}/details`);
};

export default {
    getBalance,
    initiatePayment,
    getPaymentDetails
};
