import prePaymentService from '../services/pre-payment-service';

const state = {
    loading: true,
    tariffs: null,
    defaultTariff: null,
    prepaySummary: null
};

const getters = {
    LOADING: (state) => state.loading,
    TARIFFS: (state) => state.tariffs,
    DEFAULTTARIFF: (state) => state.defaultTariff,
    PREPAY_SUMMARY: (state) => state.prepaySummary
};

const mutations = {
    SET_LOADING: (state, value) => {
        state.loading = value;
    },
    SET_TARIFFS: (state, value) => {
        state.tariffs = value;
    },
    SET_DEFAULTTARIFF: (state, value) => {
        state.defaultTariff = value;
    },
    SET_PREPAY_SUMMARY: (state, value) => {
        state.prepaySummary = value;
    }
};

const actions = {
    LOAD_BALANCE: ({ commit }) => {
        commit('SET_LOADING', true);
        prePaymentService.getPrepayModel().then((model) => {
            commit('SET_TARIFFS', model.tariffs);
            commit('SET_DEFAULTTARIFF', model.defaultTariff);
            commit('SET_PREPAY_SUMMARY', model.prepaySummary);

            commit('SET_LOADING', false);
        });
    }
};

export const balancePrepay = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
