import router from '../router';
import loginService from '../services/login-service';
import parseHttpError from '../utility/parse-http-error';
import urlUtility from '../utility/url-utility';

const state = {
    phoneNumber: '',
    loginType: '',
    phonePassed: false,
    phoneServerSideError: null,
    pinCodeServerSideError: null,
    processing: false
};

const getters = {
    PHONE_NUMBER: (state) => state.phoneNumber,
    LOGIN_TYPE: (state) => state.loginType,
    PHONE_PASSED: (state) => state.phonePassed,
    PHONE_SERVER_ERROR: (state) => state.phoneServerSideError,
    PINCODE_SERVER_ERROR: (state) => state.pinCodeServerSideError,
    PROCESSING: (state) => state.processing
};

const mutations = {
    PASS_PHONE_NUMBER: (state, { phoneNumber, loginType }) => {
        state.phoneNumber = phoneNumber;
        state.loginType = loginType;
        state.phonePassed = true;
    },
    REENTER_PHONE: (state) => {
        state.phonePassed = false;
    },
    SET_PHONE_SERVER_ERROR: (state, error) => {
        state.phoneServerSideError = error;
    },
    SET_PINCODE_SERVER_ERROR: (state, error) => {
        state.pinCodeServerSideError = error;
    },
    SET_PROCESSING: (state, value) => {
        state.processing = value;
    },
    ON_PAGE_LOAD: (state) => {
        state.loginType = '';
        state.phonePassed = false;
        state.phoneServerSideError = null;
        state.pinCodeServerSideError = null;
        state.processing = false;
    }
};

const actions = {
    SUBMIT_PHONE: ({ commit }, phoneNumber) => {
        commit('SET_PHONE_SERVER_ERROR', null);
        commit('SET_PINCODE_SERVER_ERROR', null);
        commit('SET_PROCESSING', true);

        loginService
            .loginPhoneNumber(phoneNumber)
            .then(({ loginType }) => commit('PASS_PHONE_NUMBER', { phoneNumber, loginType }))
            .catch((err) => commit('SET_PHONE_SERVER_ERROR', parseHttpError(err)))
            .finally(() => commit('SET_PROCESSING', false));
    },

    SUBMIT_PIN: ({ commit, getters }, pinCode) => {
        commit('SET_PHONE_SERVER_ERROR', null);
        commit('SET_PINCODE_SERVER_ERROR', null);
        commit('SET_PROCESSING', true);

        loginService
            .verifyPhoneNumber(getters.PHONE_NUMBER, pinCode)
            .then((authInfo) => {
                commit('authStorage/SET_TOKEN', authInfo.accessToken, { root: true });
                router.push(urlUtility.queryParam('returnurl') || { name: 'Route_PostpayOverview' });
            })
            .catch((err) => commit('SET_PINCODE_SERVER_ERROR', parseHttpError(err)))
            .finally(() => commit('SET_PROCESSING', false));
    },

    REENTER_PHONE: ({ commit }) => {
        commit('REENTER_PHONE');
    },

    ON_PAGE_LOAD: ({ commit }) => {
        commit('ON_PAGE_LOAD');
    }
};

export const pageLogin = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
