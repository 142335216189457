<template>
    <div v-if="IS_AUTHENTICATED">
        <div class="logout-panel">
            <div class="logout-panel-info">
                <p class="fs-6 mb-0 mx-2 fst-italic">{{ t('layout.login_phone') }} {{ PHONE_NUMBER }}</p>
            </div>

            <button class="btn btn-primary" @click="LOG_OUT()">{{ t('layout.logout') }}</button>
        </div>
    </div>
</template>

<script>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import PageSection from '../components/PageSection.vue';

    export default {
        setup() {
            const { t } = useI18n();
            const store = useStore();

            return {
                t,
                IS_AUTHENTICATED: computed(() => store.getters['authStorage/IS_AUTHENTICATED']),
                PHONE_NUMBER: computed(() => store.getters['authStorage/PHONE_NUMBER']),
                LOG_OUT: () => store.dispatch('authStorage/LOG_OUT')
            };
        },
        components: { PageSection }
    };
</script>

<style lang="scss" scoped>
    ::v-deep(.bg-light) {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
    }

    .logout-panel {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logout-panel-info {
            display: flex;
            align-items: center;
        }

        button {
            white-space: nowrap;
        }
    }
</style>
