<template>
    <div class="col-sm-6 mt-4 mb-3 msd-feature-wap">
        <router-link
            v-if="!linkto.startsWith('http')"
            :to="{ name: linkto }"
            class="card msd-feature"
            :class="cssClass"
        >
            <div class="card-body text-center d-flex align-items-center justify-content-center">
                <!-- <div class="msd-feature__icon rounded-3 mt-n5">
                    <img :src="image" />
                </div> -->
                <h2 class="m-1">{{ title }}</h2>
                <!-- <p>{{ text }}</p> -->
            </div>
        </router-link>

        <a v-else :href="linkto" target="_blank" class="card msd-feature" :class="cssClass">
            <div class="card-body text-center d-flex align-items-center justify-content-center">
                <!-- <div class="msd-feature__icon rounded-3 mt-n5">
                    <img :src="image" />
                </div> -->
                <h2 class="m-1">{{ title }}</h2>
                <!-- <p>{{ text }}</p> -->
            </div>
        </a>
    </div>
</template>

<script>
    export default {
        name: 'FeatureItem',
        props: {
            image: String,
            title: String,
            text: String,
            linkto: String,
            cssClass: String
        },
        setup() {}
    };
</script>

<style lang="scss" scoped>
    .msd-feature-wap {
        display: flex;
    }

    .msd-feature,
    .msd-feature__icon {
        transition: 0.5s ease all;
    }

    .msd-feature {
        text-decoration: none;
        color: #000;
        width: 100%;
        border-radius: 10px;
        min-height: 90px;
        @media (min-width: 425px) {
            min-height: 70px;
        }

        .msd-feature__icon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 64px;
            height: 64px;
            background: rgb(13, 110, 253);

            img {
                width: 60%;
            }
        }

        &:hover {
            box-shadow: 0px 2px 6px 4px #e5e5e5;

            .msd-feature__icon {
                background: #0b5ed7;
                box-shadow: 0px 2px 6px 4px #e5e5e5;
            }
        }
    }

    .white {
        background-color: #ffffff;
        border-color: #cccccc;
    }

    .blue {
        background-color: #aee9fbff;
    }
</style>
