import httpClient from './http-client';

const getPrepayModel = () => {
    return httpClient.get('/api/pre-payments/getprepaymodel');
};

const getPrepayDetails = () => {
    return httpClient.get('/api/pre-payments/getprepaydetails');
};

const initiatePayment = ({ email, minutes, ccid }) => {
    return httpClient.post('/api/pre-payments/initiate-payment', { email, minutes, ccid });
};

const getPaymentDetails = (paymentId) => {
    return httpClient.get(`/api/pre-payments/${paymentId}/details`);
};

export default {
    getPrepayModel,
    getPrepayDetails,
    initiatePayment,
    getPaymentDetails
};
