import store from '../store';

const authGuard = (to, from) => {
    // if route does not require authorization, allowing navigation
    if (!to.meta.auth) {
        return true;
    }

    // checking that at least a user authorized in MinSide
    if (!store.getters['authStorage/IS_AUTHENTICATED']) {
        return forbidNavigation(to, from);
    }

    // checking current user permissions to match permissions, required for target page
    const requiredPermissions = to.meta.auth.permissions || [];
    for (let i = 0; i < requiredPermissions.length; i++) {
        if (!store.getters['authStorage/HAS_PERMISSION'](requiredPermissions[i])) {
            return forbidNavigation(to, from);
        }
    }
};

const forbidNavigation = (to, from) => {
    return {
        name: to.meta.auth.redirectUnauthorized || 'Route_Home',
        query: { returnUrl: to.fullPath }
    };
};

export default authGuard;
