import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/Home/HomePage.vue';
import NotFoundPage from '../views/NotFoundPage.vue';
import DinteroCheckoutProcessingPage from '../views/DinteroCheckoutProcessingPage.vue';
import appStateGuard from './app-state-guard';
import authGuard from './auth-guard';
import scrollBehavior from './scroll-behavior';
import { urls_no } from './urls-no';
import { urls_sv } from './urls-sv';

const msdLocale = process.env.VUE_APP_MSD_LOCALE;
const urlsLocalized = msdLocale === 'No' ? urls_no : msdLocale === 'Sv' ? urls_sv : null;
if (!urlsLocalized) {
    throw 'Url schema not found !';
}

const routes = [
    {
        path: '/',
        name: 'Route_Home',
        component: HomePage
    },
    {
        path: '/payment/processing/dintero',
        component: DinteroCheckoutProcessingPage
    },
    {
        path: urlsLocalized.Login,
        name: 'Route_Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginPage.vue')
    },
    {
        path: urlsLocalized.PostpayOverview,
        name: 'Route_PostpayOverview',
        component: () =>
            import(/* webpackChunkName: "postpay-overview" */ '../views/PostpayOverview/PostpayOverviewPage.vue'),
        meta: {
            auth: {
                permissions: ['PostPaymentsAccess'],
                redirectUnauthorized: 'Route_Login'
            }
        }
    },
    {
        path: urlsLocalized.PostpaySuccess,
        name: 'Route_PostpaySuccess',
        component: () => import(/* webpackChunkName: "postpay-success" */ '../views/PostpaySuccessPage.vue'),
        meta: {
            auth: {
                permissions: ['PostPaymentsAccess'],
                redirectUnauthorized: 'Route_Login'
            }
        }
    },
    {
        path: urlsLocalized.PrepayOverview,
        name: 'Route_PrepayOverview',
        component: () =>
            import(/* webpackChunkName: "prepay-overview" */ '../views/PrepayOverview/PrepayOverviewPage.vue'),
        meta: {
            auth: {
                permissions: ['PrePaymentsAccess'],
                redirectUnauthorized: 'Route_Login'
            }
        }
    },
    {
        path: urlsLocalized.PrepayDetails,
        name: 'Route_PrepayDetails',
        component: () =>
            import(/* webpackChunkName: "prepay-details" */ '../views/PrepayOverview/PrepayDetailsPage.vue'),
        meta: {
            auth: {
                permissions: ['PrePaymentsAccess'],
                redirectUnauthorized: 'Route_Login'
            }
        }
    },
    {
        path: urlsLocalized.PrepaySuccess,
        name: 'Route_PrepaySuccess',
        component: () =>
            import(/* webpackChunkName: "prepay-success" */ '../views/PrepayOverview/PrepaySuccessPage.vue'),
        meta: {
            auth: {
                permissions: ['PrePaymentsAccess'],
                redirectUnauthorized: 'Route_Login'
            }
        }
    },
    {
        path: urlsLocalized.GiftCardOverview,
        name: 'Route_GiftCardOverview',
        component: () =>
            import(/* webpackChunkName: "gift-card-overview" */ '../views/GiftCard/GiftCardOverviewPage.vue'),
        meta: {
            auth: {
                permissions: ['PrePaymentsAccess'],
                redirectUnauthorized: 'Route_Login'
            }
        }
    },
    {
        path: urlsLocalized.GiftCardSuccess,
        name: 'Route_GiftCardSuccess',
        component: () =>
            import(/* webpackChunkName: "gift-card-success" */ '../views/GiftCard/GiftCardSuccessPage.vue'),
        meta: {
            auth: {
                permissions: ['PrePaymentsAccess'],
                redirectUnauthorized: 'Route_Login'
            }
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Route_404',
        component: NotFoundPage
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior
});

router.beforeEach((to, from) => {
    appStateGuard(to, from);
    authGuard(to, from);
});

export default router;
