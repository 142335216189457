<!-- template is taken from: https://startbootstrap.com/previews/heroic-features -->
<template>
    <div class="page-wrapper">
        <PageSection>
            <h1>{{ t('home.title') }}</h1>
            <p class="fs-3">{{ t('home.sub_title') }}</p>

            <div class="container">
                <div class="row">
                    <FeatureItem v-if="!!features.prePayment"
                        :title="t('home.prepay_title')"
                        :text="t('home.prepay_text')"
                        linkto="Route_PrepayOverview"
                        cssClass="white"
                    />

                    <FeatureItem v-if="!!features.postPayment"
                        :title="t('home.postpay_title')"
                        :text="t('home.postpay_text')"
                        linkto="Route_PostpayOverview"
                        cssClass="blue"
                    />
                </div>
                <div class="row">
                    <FeatureItem v-if="!!features.giftCard"
                        :title="t('home.gift_card_title')"
                        :text="t('home.gift_card_text')"
                        linkto="Route_GiftCardOverview"
                        cssClass="white"
                    />
                </div>
            </div>
        </PageSection>
    </div>
</template>

<script setup>
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    import FeatureItem from './FeatureItem.vue';
    import PageSection from '../../components/PageSection.vue';

    const { t } = useI18n();

    const store = useStore();
    const features = store.getters['appSettings/FEATURES'];
</script>
