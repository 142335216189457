// NOTE: urls are duplicated on the back-end in ClientSidePage.cs file

export const urls_sv = {
    Login: '/logga-in',
    PostpayOverview: '/betalning/oversikt',
    PostpaySuccess: '/betalning/:paymentId/lyckades',
    PrepayOverview: '/kop/oversikt',
    PrepayDetails: '/kop/detaljer',
    PrepaySuccess: '/kop/:paymentId/lyckades',
    GiftCardOverview: '/gift-card-sv/overview-sv',
    GiftCardSuccess: '/gift-card-sv/:paymentId/success-sv'
};
