import axios from 'axios';
import i18n from './index';

const i18n_locale = process.env.VUE_APP_I18N_LOCALE;

const loadTranslations = () =>
    axios
        .get(`/i18n/${i18n_locale}.json`)
        .then((translations) => i18n.global.setLocaleMessage(i18n_locale, translations.data));

export default loadTranslations;
