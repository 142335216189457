<template>
    <div class="d-flex flex-column h-100">
        <HeaderSection :showLogout="showLogout" />

        <router-view v-slot="{ Component }">
            <component :is="Component" />
        </router-view>

        <FooterSection v-once />
    </div>
</template>

<script setup>
    import { watch, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import HeaderSection from './components/HeaderSection.vue';
    import FooterSection from './components/FooterSection.vue';

    const route = useRoute();

    const showLogout = ref(false);

    watch(
        () => route.path,
        () => (showLogout.value = !!route.meta.auth)
    );
</script>
