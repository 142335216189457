import httpClient from './http-client';

const loginPhoneNumber = (phoneNumber) => {
    return httpClient.post('/api/account/phone-number/login', { phoneNumber });
};

const verifyPhoneNumber = (phoneNumber, pinCode) => {
    return httpClient.post('/api/account/phone-number/verify', { phoneNumber, pinCode });
};

export default {
    loginPhoneNumber,
    verifyPhoneNumber
};
