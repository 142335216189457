const state = {
    source_app_code: null,
    ccid: null
};

const getters = {
    SOURCE_APP_CODE: (state) => state.source_app_code,
    CCID: (state) => state.ccid
};

const mutations = {
    SET_SOURCE_APP_CODE: (state, value) => {
        state.source_app_code = value;
    },
    SET_CCID: (state, value) => {
        state.ccid = value;
    }
};

const actions = {
    UPDATE_SOURCE_APP_CODE: ({ commit }, g_code) => {
        if (!!g_code) {
            commit('SET_SOURCE_APP_CODE', g_code);
        }
    },
    UPDATE_CCID: ({ commit }, ccid) => {
        if (!!ccid) {
            commit('SET_CCID', ccid);
        }
    }
};

export const appStateStorage = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
