import giftCardService from '../services/gift-card-service';
import parseHttpError from '../utility/parse-http-error';

const state = {
    loading: true,
    tariffs: null,
    defaultTariff: null,

    initiatingPayment: false,
    initiationError: null,

    dinteroPaymentId: null
};

const getters = {
    LOADING: (state) => state.loading,
    TARIFFS: (state) => state.tariffs,
    DEFAULTTARIFF: (state) => state.defaultTariff,

    INITIATING_PAYMENT: (state) => state.initiatingPayment,
    INITIATION_ERROR: (state) => state.initiationError,

    DINTERO_PAYMENT_ID: (state) => state.dinteroPaymentId
};

const mutations = {
    SET_LOADING: (state, value) => (state.loading = value),
    SET_TARIFFS: (state, value) => (state.tariffs = value),
    SET_DEFAULTTARIFF: (state, value) => (state.defaultTariff = value),

    SET_INITIATING_PAYMENT: (state, value) => (state.initiatingPayment = value),
    SET_INITIATION_ERROR: (state, value) => (state.initiationError = value),

    SET_DINTERO_PAYMENT_ID: (state, value) => (state.dinteroPaymentId = value),

    ON_PAGE_LOAD: (state) => {
        state.initiatingPayment = false;
        state.initiationError = null;
        state.dinteroPaymentId = null;
    }
};

const actions = {
    LOAD_TARIFFS: ({ commit }) => {
        commit('SET_LOADING', true);
        giftCardService.getTariffs().then((model) => {
            commit('SET_TARIFFS', model.tariffs);
            commit('SET_DEFAULTTARIFF', model.defaultTariff);

            commit('SET_LOADING', false);
        });
    },
    INITIATE_PAYMENT: ({ commit }, { email, minutes, receiverPhoneNumber, ccid }) => {
        commit('SET_INITIATION_ERROR', null);
        commit('SET_INITIATING_PAYMENT', true);

        giftCardService
            .initiatePayment({ email, minutes, receiverPhoneNumber, ccid })
            .then(({ paymentId }) => {
                commit('SET_DINTERO_PAYMENT_ID', paymentId);
            })
            .catch((err) => commit('SET_INITIATION_ERROR', parseHttpError(err)))
            .finally(() => commit('SET_INITIATING_PAYMENT', false));
    },

    ON_PAGE_LOAD: ({ commit }) => {
        commit('ON_PAGE_LOAD');
    }
};

export const pageGiftCardOverview = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
