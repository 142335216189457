<template>
    <footer class="flex-shrink-0 py-4">
        <div class="container">
            <hr />
            <div class="d-flex flex-column flex-sm-row justify-content-around">
                <div class="">
                    <p>
                        <strong>{{ t('footer.customer_service_email_label') }}</strong>
                        <br />
                        {{ t('footer.customer_service_email_data') }}
                    </p>
                </div>
                <div :class="{'d-none' : !t('footer.customer_service_phone_data')}">
                    <p>
                        <strong>{{ t('footer.customer_service_phone_label') }}</strong>
                        <br />
                        {{ t('footer.customer_service_phone_data') }}
                    </p>
                </div>
                <div class="">
                    <p>
                        <strong>{{ t('footer.customer_service_address_label') }}</strong>
                        <br />
                        {{ t('footer.customer_service_address_data') }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <img v-if="APP_LOCALE === 'No'" class="img-fluid" src="/app-logos/molendo-as-logo.png" />
                    <img v-if="APP_LOCALE === 'Sv'" class="img-fluid" src="/app-logos/molendo-as-logo.png" />
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';

    const { t } = useI18n();

    const store = useStore();
    const APP_LOCALE = store.getters['appSettings/APP_LOCALE'];
</script>
