// NOTE: urls are duplicated on the back-end in ClientSidePage.cs file

export const urls_no = {
    Login: '/logg-inn',
    PostpayOverview: '/betaling/oversikt',
    PostpaySuccess: '/betaling/:paymentId/suksess',
    PrepayOverview: '/kjop/oversikt',
    PrepayDetails: '/kjop/detaljer',
    PrepaySuccess: '/kjop/:paymentId/suksess',
    GiftCardOverview: '/gavekort/oversikt',
    GiftCardSuccess: '/gavekort/:paymentId/suksess'
};
