import postPaymentService from '../services/post-payment-service';

const state = {
    loading: true,
    balance: null // { amount, currency }
};

const getters = {
    BALANCE_LOADING: (state) => state.loading,
    BALANCE_AMOUNT: (state) => state.balance?.amount,
    BALANCE_CURRENCY: (state) => state.balance?.currency
};

const mutations = {
    SET_BALANCE_LOADING: (state, value) => {
        state.loading = value;
    },
    SET_BALANCE: (state, value) => {
        state.balance = value ? { amount: value.amount, currency: value.currency } : null;
    }
};

const actions = {
    LOAD_BALANCE: ({ commit }) => {
        commit('SET_BALANCE_LOADING', true);
        postPaymentService.getBalance().then((balance) => {
            commit('SET_BALANCE', balance);
            commit('SET_BALANCE_LOADING', false);
        });
    }
};

export const balancePostpay = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
