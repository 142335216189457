import prePaymentService from '../services/pre-payment-service';
import parseHttpError from '../utility/parse-http-error';

const state = {
    initiatingPayment: false,
    initiationError: null,

    dinteroPaymentId: null
};

const getters = {
    INITIATING_PAYMENT: (state) => state.initiatingPayment,
    INITIATION_ERROR: (state) => state.initiationError,

    DINTERO_PAYMENT_ID: (state) => state.dinteroPaymentId
};

const mutations = {
    SET_INITIATING_PAYMENT: (state, value) => (state.initiatingPayment = value),
    SET_INITIATION_ERROR: (state, value) => (state.initiationError = value),

    SET_DINTERO_PAYMENT_ID: (state, value) => (state.dinteroPaymentId = value),

    ON_PAGE_LOAD: (state) => {
        state.initiatingPayment = false;
        state.initiationError = null;
        state.dinteroPaymentId = null;
    }
};

const actions = {
    INITIATE_PAYMENT: ({ commit }, { email, minutes, ccid }) => {
        commit('SET_INITIATION_ERROR', null);
        commit('SET_INITIATING_PAYMENT', true);

        prePaymentService
            .initiatePayment({ email, minutes, ccid })
            .then(({ paymentId }) => {
                commit('SET_DINTERO_PAYMENT_ID', paymentId);
            })
            .catch((err) => commit('SET_INITIATION_ERROR', parseHttpError(err)))
            .finally(() => commit('SET_INITIATING_PAYMENT', false));
    },

    ON_PAGE_LOAD: ({ commit }) => {
        commit('ON_PAGE_LOAD');
    }
};

export const pagePrepayOverview = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
