<template>
    <header class="text-center">
        <div class="container">
            <div class="row align-items-center mt-3">
                <div class="col-sm-4">
                    <a v-if="SOURCE_APP_CODE === 'mc'" :href="SOURCE_APP_URL">
                        <img src="/app-logos/mc-logo.png" />
                    </a>
                    <router-link v-else :to="{ name: 'Route_Home' }">
                        <img v-if="APP_LOCALE === 'No'" src="/app-logos/molendo-logo.png" />
                        <img v-if="APP_LOCALE === 'Sv'" src="/app-logos/molendo-logo.png" />
                    </router-link>
                </div>
                <div class="col-sm-4"></div>
                <div class="col-sm-4"><LogOut v-if="showLogout" class="mt-4" /></div>
            </div>

            <hr />
        </div>
    </header>
</template>

<script>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import LogOut from './LogOut.vue';

    export default {
        setup() {
            const store = useStore();

            return { 
                SOURCE_APP_CODE: computed(() => store.getters['appStateStorage/SOURCE_APP_CODE']),
                SOURCE_APP_URL: computed(() => store.getters['appSettings/MAGIC_CIRCLE_URL']),
                APP_LOCALE: store.getters['appSettings/APP_LOCALE']
             };
        },
        props: {
            showLogout: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        components: { LogOut }
    };
</script>
