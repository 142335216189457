import { createI18n } from 'vue-i18n';

const i18n_locale = process.env.VUE_APP_I18N_LOCALE;

const i18n = createI18n({
    legacy: false,
    locale: i18n_locale
});

export default i18n;
