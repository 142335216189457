import prePaymentService from '../services/pre-payment-service';

const state = {
    prepayLoading: true,
    prepayDetails: null // { prepayDetailsIn, prepayDetailsOut }
};

const getters = {
    PREPAY_LOADING: (state) => state.prepayLoading,
    PREPAY_DETAILS: (state) => state.prepayDetails
};

const mutations = {
    SET_PREPAY_LOADING: (state, value) => (state.prepayLoading = value),
    SET_PREPAY_DETAILS: (state, value) => (state.prepayDetails = value)
};

const actions = {
    LOAD_PREPAY_DETAILS: ({ commit }) => {
        commit('SET_PREPAY_LOADING', true);
        prePaymentService
            .getPrepayDetails()
            .then((model) => commit('SET_PREPAY_DETAILS', model))
            .catch(() => commit('SET_PREPAY_DETAILS', null))
            .finally(() => commit('SET_PREPAY_LOADING', false));
    }
};

export const pagePrepayDetails = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
