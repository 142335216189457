import axios from 'axios';
import store from '../store';
import router from '../router';
import urlUtility from '../utility/url-utility';

const httpClient = axios.create();

httpClient.interceptors.request.use(function(config) {
    config.headers.Authorization = 'Bearer ' + store.getters['authStorage/ACCESS_TOKEN'];
    return config;
});

httpClient.interceptors.response.use(
    function(response) {
        return response.data;
    },

    function(error) {
        if (error?.response?.status === 401) {
            store.dispatch('authStorage/CLEAR');

            router.push({
                name: router.currentRoute.value.meta.auth.redirectUnauthorized || 'Route_Home',
                query: { returnUrl: urlUtility.currentUrl }
            });
        }

        return Promise.reject(error);
    }
);

export default httpClient;
