<template>
    <div class="page-wrapper">
        <Spinner :fullScreen="true" />
    </div>
</template>

<script setup>
    import Spinner from '../components/Spinner.vue';
    import { useRouter, useRoute } from 'vue-router';

    const router = useRouter();
    const route = useRoute();

    // When Dintero payment completed successfully - 'transaction_id' query-param presents, otherwise 'error' query param appears
    if (route.query.ptype === 'prepay') {
        if (route.query.transaction_id) {
            router.replace({
                name: 'Route_PrepaySuccess',
                params: { paymentId: route.query.transaction_id }
            });
        } else {
            router.replace({
                name: 'Route_PrepayOverview',
                query: { error: route.query.error }
            });
        }
    } else if (route.query.ptype === 'giftcard') {
        if (route.query.transaction_id) {
            router.replace({
                name: 'Route_GiftCardSuccess',
                params: { paymentId: route.query.transaction_id }
            });
        } else {
            router.replace({
                name: 'Route_GiftCardOverview',
                query: { error: route.query.error }
            });
        }
    } else {
        if (route.query.transaction_id) {
            router.replace({
                name: 'Route_PostpaySuccess',
                params: { paymentId: route.query.transaction_id }
            });
        } else {
            router.replace({
                name: 'Route_PostpayOverview',
                query: { error: route.query.error }
            });
        }
    }
</script>
