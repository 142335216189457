const urlUtility = {
    get currentUrl() {
        return window.location.pathname + window.location.search;
    },

    queryParam: (paramName) => {
        var queryParams = new URLSearchParams(window.location.search);

        for (let key of queryParams.keys()) {
            if (key.toLowerCase() === paramName.toLowerCase()) {
                return queryParams.get(key);
            }
        }

        return null;
    }
};

export default urlUtility;
