import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import loadTranslations from './i18n/load-translations';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import './assets/styles.scss';

Promise.all([loadTranslations(), store.dispatch('appSettings/LOAD')]).then(() => {
    createApp(App)
        .use(store)
        .use(router)
        .use(i18n)
        .mount('#app');
});
