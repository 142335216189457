import prePaymentService from '../services/pre-payment-service';

const state = {
    paymentLoading: true,
    paymentDetails: null // { id, orderNo, createdAt, amount, currency, minutes, status, customerEmail, customerPhone  }
};

const getters = {
    PAYMENT_LOADING: (state) => state.paymentLoading,
    PAYMENT_DETAILS: (state) => state.paymentDetails
};

const mutations = {
    SET_PAYMENT_LOADING: (state, value) => (state.paymentLoading = value),
    SET_PAYMENT: (state, payment) => (state.paymentDetails = payment)
};

const actions = {
    LOAD_PAYMENT_DETAILS: ({ commit }, paymentId) => {
        commit('SET_PAYMENT_LOADING', true);
        prePaymentService
            .getPaymentDetails(paymentId)
            .then((payment) => commit('SET_PAYMENT', payment))
            .catch(() => commit('SET_PAYMENT', null))
            .finally(() => commit('SET_PAYMENT_LOADING', false));
    }
};

export const pagePrepaySuccess = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
