import { createStore } from 'vuex';
import { appSettings } from './app-settings';
import { appStateStorage } from './app-state-storage';
import { appStateStoragePersist } from './app-state-storage.persist';
import { authStorage } from './auth-storage';
import { authStoragePersist } from './auth-storage.persist';
import { balancePostpay } from './balance-postpay';
import { balancePrepay } from './balance-prepay';
import { customerPreferences } from './customer-preferences';
import { customerPreferencesPersist } from './customer-preferences.persist';
import { pageLogin } from './page-login';
import { pageLoginPersist } from './page-login.persist';
import { pagePostpayOverview } from './page-postpay-overview';
import { pagePostpaySuccess } from './page-postpay-success';
import { pagePrepayOverview } from './page-prepay-overview';
import { pagePrepayDetails } from './page-prepay-details';
import { pagePrepaySuccess } from './page-prepay-success';
import { pageGiftCardOverview } from './page-gift-card-overview';
import { pageGiftCardSuccess } from './page-gift-card-success';

export default createStore({
    modules: {
        appSettings,
        appStateStorage,
        authStorage,
        balancePostpay,
        balancePrepay,
        customerPreferences,
        pageLogin,
        pagePostpayOverview,
        pagePostpaySuccess,
        pagePrepayOverview,
        pagePrepayDetails,
        pagePrepaySuccess,
        pageGiftCardOverview,
        pageGiftCardSuccess
    },
    plugins: [
        appStateStoragePersist.plugin,
        authStoragePersist.plugin,
        customerPreferencesPersist.plugin,
        pageLoginPersist.plugin
    ]
});
