import settingsService from '../services/settings-service';

const state = {
    values: null
};

const getters = {
    APP_LOCALE: (state) => state.values.locale,
    NETS_CHECKOUT_KEY: (state) => state.values.netsEasyCheckoutKey,
    NETS_IS_PROD: (state) => state.values.netsEasyProdMode,
    POST_PAYMENT_ENGINE: (state) => state.values.postPaymentEngine,
    POST_PAYMENT_ENGINE_LOGO: (state) => state.values.postPaymentEngineLogo,
    MAGIC_CIRCLE_URL: (state) => state.values.magicCircleUrl,
    FEATURES: (state) => state.values.features
};

const mutations = {
    SET_VALUES: (state, values) => {
        state.values = { ...values };
    }
};

const actions = {
    LOAD: ({ commit }) =>
        settingsService
            .loadGlobalSettings()
            .then((values) => {
                // Verifying that js bundle locale matches back-end locale
                if (values.locale !== process.env.VUE_APP_MSD_LOCALE) {
                    throw 'Invalid application settings retrieved !!!';
                } else {
                    return values;
                }
            })
            .then((values) => commit('SET_VALUES', values))
};

export const appSettings = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
