const parseHttpError = (httpError) => {
    let errorStr;

    const statusCode = httpError.response.status;
    const errData = httpError.response.data;

    if (statusCode === 500) {
        errorStr = !errData || typeof errData === 'string' ? errData : JSON.stringify(errData);
    } else {
        let errors = [];
        if (typeof errData === 'string') {
            errors.push(errData);
        } else {
            for (let errProp in errData) {
                errors.push(errData[errProp].toString());
            }
        }

        errorStr = errors.join(', ');
    }

    return errorStr || 'Something went wrong...';
};

export default parseHttpError;
