import httpClient from './http-client';

const getTariffs = () => {
    return httpClient.get('/api/gift-cards/tariffs');
};

const initiatePayment = ({ email, minutes, receiverPhoneNumber, ccid }) => {
    return httpClient.post('/api/gift-cards/initiate-payment', { email, minutes, receiverPhoneNumber, ccid });
};

const getPaymentDetails = (paymentId) => {
    return httpClient.get(`/api/gift-cards/${paymentId}/details`);
};

export default {
    getTariffs,
    initiatePayment,
    getPaymentDetails
};
