<template>
    <div class="page-wrapper">
        <PageSection>
            <h1>404</h1>
            <h3 class="mb-4">{{ t('not_found.title') }}</h3>
            <router-link :to="{ name: 'Route_Home' }">{{ t('not_found.back_link') }}</router-link>
        </PageSection>
    </div>
</template>

<script setup>
    import { useI18n } from 'vue-i18n';
    import PageSection from '../components/PageSection.vue';

    const { t } = useI18n();
</script>
