<template>
    <div class="container mt-3 text-center">
        <div
            class="bg-light rounded-3 py-4 px-3 px-md-4 position-relative"
            :style="loading && { 'min-height': '90px' }"
        >
            <Spinner v-if="loading" :overlayParent="true" />
            <slot v-else></slot>
        </div>
    </div>
</template>

<script>
    import Spinner from './Spinner.vue';

    export default {
        props: {
            loading: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        components: { Spinner }
    };
</script>
