import makeService from '../services/make-service';

const state = {
    recentMailbox: '',
    newsletterConsent: false
};

const getters = {
    RECENT_MAILBOX: (state) => state.recentMailbox,
    NEWSLETTER_CONSENT: (state) => state.newsletterConsent
};

const mutations = {
    SET_RECENT_MAILBOX: (state, value) => (state.recentMailbox = value),
    SET_NEWSLETTER_CONSENT: (state, value) => (state.newsletterConsent = value)
};

const actions = {
    UPDATE_CUSTOMER_PREFERENCES: ({ commit }, { email, newsletterConsent }) => {
        if (!!email) {
            commit('SET_RECENT_MAILBOX', email);

            if (newsletterConsent) {
                makeService
                    .subscribeToNewsletter(email)
                    .then(({ isSuccess, errorMessage }) => {
                        if (isSuccess) {
                            commit('SET_NEWSLETTER_CONSENT', newsletterConsent);
                        }
                    })
                    .catch((err) => {});
            }
        }
    }
};

export const customerPreferences = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
