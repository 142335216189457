import jwt_decode from 'jwt-decode';
import router from '../router';

const state = {
    accessToken: '',
    phoneNumber: '',
    permissions: []
};

const getters = {
    IS_AUTHENTICATED: (state) => !!state.accessToken,
    ACCESS_TOKEN: (state) => state.accessToken,
    PHONE_NUMBER: (state) => state.phoneNumber,
    HAS_PERMISSION: (state) => (permission) => {
        return state.permissions.indexOf(permission) !== -1;
    }
};

const mutations = {
    SET_TOKEN: (state, jwt) => {
        // setting the token
        state.accessToken = jwt;

        // decoding the token
        let jwtDecoded;
        try {
            jwtDecoded = jwt_decode(jwt);
        } catch {
            jwtDecoded = {};
        }

        // storing user's phone number
        state.phoneNumber = jwtDecoded?.PhoneNumber;

        // setting permissions from the token
        state.permissions = [];
        if (jwtDecoded?.PostPaymentsAccess === 'granted') {
            state.permissions.push('PostPaymentsAccess');
        }
        if (jwtDecoded?.PrePaymentsAccess === 'granted') {
            state.permissions.push('PrePaymentsAccess');
        }
    },
    CLEAR: (state) => {
        state.accessToken = '';
        state.phoneNumber = '';
        state.permissions = [];
    }
};

const actions = {
    LOG_OUT: ({ commit }) => {
        commit('CLEAR');
        router.push({ name: 'Route_Home' });
    },
    CLEAR: ({ commit }) => {
        commit('CLEAR');
    }
};

export const authStorage = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
